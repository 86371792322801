main {
  margin: 2em;
}

.invoice {
  padding: 4em;
  border: 1px solid whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80vh;
}

.invoice-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.invoice-address-container {
  display: flex;
  flex-direction: column;
}

.invoice-address {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
}

table, th, td  {
  margin-top: 3em;
}

.invoice-total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: bold;
}

main {
  width: 80%;
  margin: 0 auto;
  margin-top: 1em;
}

.form {
  margin: 0 auto;
  width: 550px;
  margin-top: 1em;
}

.notification {
  width: fit-content;
  margin: 0 auto;
  min-height: 35px;
  text-align: center;
  margin-top: .5em;
}

.center {
  text-align: center;
  margin-bottom: 1em;
}